<template>
  <div class="wrapper">
    <!-- Include the common sidebar and navbar components -->
    <nav-bar />
    <side-bar />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper" style="min-height: 100vh;">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <h1 class="m-0">Modelo de Documentos</h1>
        </div>
      </div>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <!-- Add New Document Button -->
            <div class="col-sm-3" v-on:click="createNewDocument">
              <div class="card">
                <div class="card-body text-center">
                  <font-awesome-icon class="fa-4x" :icon="['fas', 'plus']" />
                  <p>Criar Novo Documento</p>
                </div>
              </div>
            </div>

            <!-- Template Items -->
            <div class="col-sm-3" v-for="(template, index) in templates" :key="index"
              @click="createNewDocument(template)">
              <div class="card">
                <div class="card-body text-center">
                  <font-awesome-icon class="fa-4x" :icon="['fas', 'plus']" />
                  <p>{{ template.name }}</p>
                </div>
              </div>
            </div>
            <!-- Repeat for other templates -->
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import SideBar from './SideBar.vue';
import http from '@/utils/http'
export default {
  name: 'TemplatesGallery',
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      templates: [
      ],
    };
  },
  async mounted() {
    const templates = (await http.get('/documents/templates')).data.templates
    this.templates = templates
  },
  methods: {
    createNewDocument(template = undefined) {
      if (template === undefined) {
        this.$router.push({ name: 'DocumentForm' });
      } else {
        this.$router.push({ name: 'DocumentForm', query: { 'template_id': template.external_id } });
      }
    },
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>