import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';
import Dashboard from '../components/Dashboard.vue';
import TemplatesGallery from '../components/TemplatesGallery.vue';
import DocumentForm from '../components/DocumentForm.vue';
import DocumentPreview from '../components/DocumentPreview.vue';
import DocumentEdit from '../components/DocumentEdit.vue';

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/templates', name: 'TemplatesGallery', component: TemplatesGallery },
  { path: '/document-form', name: 'DocumentForm', component: DocumentForm },
  { path: '/document-preview/:id', name: 'DocumentPreview', component: DocumentPreview },
  { path: '/document-edit/:document_id/:section_id', name: 'DocumentEdit', component: DocumentEdit },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;