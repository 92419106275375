<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
      <span class="brand-text font-weight-light">GeniALL</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-header">MENU</li>
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
              <i class="nav-icon fas fa-home"></i>
              <p><font-awesome-icon :icon="['fas', 'home']" /> Início</p>
            </router-link>
          </li>
          <!-- Add more menu items here -->
          <li class="nav-item">
            <router-link to="/document-form" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                <font-awesome-icon :icon="['fas', 'plus-circle']" /> Novo Documento
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/templates" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                <font-awesome-icon :icon="['fas', 'star']" /> Modelos de documentos
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                <font-awesome-icon :icon="['fas', 'sign-out']" /> Sair
              </p>
            </router-link>
          </li>
          <!-- <li class="nav-header">DOCUMENTOS</li> -->
          <router-link :key="item.id" v-for="item in items" :to="`/document-preview/${item.id}`" class="nav-link">
            <i class="nav-icon fas fa-th"></i>
            <p>
              <font-awesome-icon :icon="['fas', 'file']" /> {{ item['label'] }}
            </p>
          </router-link>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      items: []
    }
  },
};
</script>