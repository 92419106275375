import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'admin-lte/dist/css/adminlte.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'jquery';
import 'popper.js';

import 'admin-lte/dist/js/adminlte.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faUser, faPlusCircle, faWandMagicSparkles, faPencilAlt,  faRefresh, faCheck, faSliders, faHome, faFile, faPlus, faStar, faPlay, faArrowLeft, faClock, faFileAlt, faCloudDownload, faSignOut, faFileWord } from '@fortawesome/free-solid-svg-icons';

library.add(faUser, faPlusCircle, faWandMagicSparkles, faPencilAlt, faRefresh, faCheck, faSliders, faHome, faFile, faPlus, faStar, faPlay, faArrowLeft, faClock, faFileAlt, faCloudDownload, faSignOut, faFileWord );

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router).mount('#app');