<template>
  <div class="wrapper">
    <nav-bar />
    <side-bar />

    <div class="content-wrapper" style="min-height: 100vh;">
      <div class="content-header">
        <div class="container-fluid">
          <h1 class="m-0">
            Editar Seção do Documento
            <button @click="$router.back()" class="btn btn-secondary float-right">
              <font-awesome-icon class="fa-lg" :icon="['fas', 'arrow-left']" /> Voltar
            </button>
          </h1>
        </div>
      </div>

      <section class="content">
        <div class="container-fluid">
          <div class="card card-primary card-outline direct-chat direct-chat-primary">
            <div class="card-header">
              <h3 class="card-title m-0">
                <font-awesome-icon class="fa-lg" :icon="['fas', 'sliders']" />
                Edição inteligente
              </h3>
            </div>
            <div class="card-body">
            </div>
            <div class="card-footer">
              <form @submit.prevent="sendMessage">
                <div class="input-group">
                  <input type="text" name="message" :disabled="loading"
                    placeholder="Qual sua sugestão para tornar o documento ainda melhor?" autocomplete="off"
                    class="form-control form-control-lg" v-model="userInput" required />
                  <span class="input-group-append">
                    <button type="submit" class="btn btn-primary">
                      <font-awesome-icon v-if="!loading" class="fa-lg" :icon="['fas', 'wand-magic-sparkles']" />
                      <font-awesome-icon v-else class="fa-lg fa-spin" :icon="['fas', 'refresh']" />
                      {{ !loading ? 'Gerar novas versões' : 'Pensando...' }}
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div class="text-center m-5 p-5" v-if="loading">
            <font-awesome-icon class="fa-4x fa-beat-fade" :icon="['fas', 'wand-magic-sparkles']" />
            <h3 class="mt-5">
              construindo algo incrível... aguarde.
              <div class="mt-4" style="font-size: 24px;">concluído {{ etapa }} de 7 etapas</div>
            </h3>
          </div>
          <div v-if="suggestions.length > 0" class="card">
            <div class="card-header">
              <h3 class="card-title">Opções de melhoria</h3>
            </div>
            <div class="card-body">
              <div v-for="(suggestion, index) in suggestions" :key="index" class="card card-outline">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h4>Opção {{ ++index }}:</h4>
                      <v-markdown-view :mode="mode" :content="suggestion.text"></v-markdown-view>
                    </div>
                    <div class="col-md-3">
                      <button class="btn btn-success btn-block" style="min-height: 90px;"
                        @click="selectSuggestion(suggestion)">
                        <font-awesome-icon class="fa-lg" :icon="['fas', 'check']" /> Selecionar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import SideBar from './SideBar.vue';
import http from '@/utils/http'
import Swal from 'sweetalert2'
import 'vue3-markdown/dist/style.css'
import { VMarkdownView } from 'vue3-markdown'
import { ref } from 'vue'

export default {
  name: 'DocumentEdit',
  components: {
    NavBar,
    SideBar,
    VMarkdownView
  },
  data() {
    return {
      loading: false,
      mode: ref('light'),
      suggestions: [],
      userInput: process.env.VUE_APP_ENVIRONMENT == 'development' ? 'altere para estar mais específico nos principais pontos da seção' : '',
      successMessage: '',
      section: undefined,
      etapa: 1
    };
  },
  methods: {
    async sendMessage() {
      this.loading = true
      const response = await (http.put(`/sections/${this.$route.params.section_id}/generate`, {
        prompt: this.userInput
      })).data
      console.log(response)

      let interval
      let ticks = 0
      interval = setInterval(async () => {
        ticks = ticks + 1
        if (ticks % 60 === 0) {
          this.etapa = this.etapa + 1
        }
        const check = await http.get(`/sections/${this.$route.params.section_id}/get-latest-revision-options`)
        this.section = check.data.section
        if (check.data.section.processing_status === 'completed') {
          this.userInput = '';
          this.suggestions = check.data.options
          clearInterval(interval)
          this.loading = false
          this.etapa = 1
        }
      }, 1000);
    },
    async selectSuggestion(revision_option) {
      console.log('revision_option', revision_option)
      const response = (await http.put(`/sections/${this.$route.params.section_id}/update-by-revision-option/${revision_option.id}`)).data
      Swal.fire({
        title: 'Seção atualizada com sucesso!',
        text: 'Seu documento já está atualizado e pronto para ser utilizado',
        icon: 'success'
      }).then(() => {
        this.$router.push({ 'name': 'DocumentPreview', params: { id: this.section.document_id } });
      });
    },
  },
};
</script>

<style scoped>
/* Add any styles if necessary */
</style>