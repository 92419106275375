<template>
  <div class="wrapper">
    <!-- Include the common sidebar and navbar components -->
    <nav-bar />
    <side-bar />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper" style="min-height: 100vh;">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <h1 class="m-0">
            Seções do Documento
            <button @click.prevent="download" class="btn btn-lg btn-success float-right">
              <font-awesome-icon :icon="['fas', 'file-word']" /> Gerar doc Word
            </button>
            <button @click="$router.back()" class="btn btn-lg btn-secondary float-right mr-2">
              <font-awesome-icon class="fa-lg" :icon="['fas', 'arrow-left']" /> Voltar
            </button>
          </h1>
        </div>
      </div>

      <!-- Main content -->
      <section class="content">
        <div class="card">

          <div class="card-body p-1">

            <div id="accordion">
              <div :key="section.id" v-for="(section, index) in documentSections" class="card card-default mt-1 mb-1">
                <div class="card-header">
                  <h4 class="card-title m-0 d-block">
                    <a class="d-block text-decoration-none" data-toggle="collapse" :href="`#collapse${section.id}`">
                      {{ ++index }}. {{ section.title }}
                    </a>
                  </h4>
                  <div class="card-tools">
                    <button class="btn btn-sm btn-outline-dark btn-tool"
                      @click.prevent="$router.push({ name: 'DocumentEdit', params: { section_id: section.id, document_id: $route.params.id } })">
                      <font-awesome-icon :icon="['fas', 'pencil-alt']" /> Modificar
                    </button>
                  </div>

                </div>
                <div :id="`collapse${section.id}`" class="collapse show" data-parent="#accordion">
                  <div class="card-body text-justify">
                    <!-- {{ section.text }} -->
                    <v-markdown-view :mode="mode"
                      :content="section.text.replaceAll('```markdown', '').replaceAll('```', '')"></v-markdown-view>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- <div class="container-fluid">
          <ul class="list-group">
            <li class="list-group-item" v-for="(section, index) in documentSections" :key="index">
              <h5>{{ section.title }}</h5>
              <p class="text-justify">{{ section.text }}</p>
              <button class="btn btn-sm btn-secondary" @click="modifySection(index)">
                Modificar
              </button>
            </li>
          </ul>
        </div> -->
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import SideBar from './SideBar.vue';
import http from '@/utils/http'
import { formToJSON } from 'axios';
import { ref } from 'vue'
import { VMarkdownView } from 'vue3-markdown'
import 'vue3-markdown/dist/style.css'
export default {
  name: 'DocumentPreview',
  components: {
    NavBar,
    SideBar,
    VMarkdownView
  },
  async mounted() {

    const response = (await http.get(`/documents/${this.$route.params.id}`)).data
    this.document = response.document
    this.documentSections = response.sections.map(s => {
      // s.text = s.text.replaceAll('\n\n', '\n')
      return s
    })
  },
  data() {
    return {
      mode: ref('light'),
      document: undefined,
      documentSections: [],
    };
  },
  methods: {
    download() {
      const base_url = process.env.VUE_APP_API_URL
      console.log('this.document', this.document)
      window.open(base_url + `documents/${this.document.id}/download`)
    },
    modifySection(index) {
      // Redirect to the document edit page with the section index
      this.$router.push({
        name: 'DocumentEdit',
        params: { sectionIndex: index },
      });
    },
  },
};
</script>

<style scoped>
/* Add any styles if necessary */
</style>