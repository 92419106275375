<template>
  <div class="wrapper">
    <nav-bar />
    <side-bar />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper" style="min-height: 100vh;">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Dashboard</h1>
            </div>
            <div class="col-sm-6">
              <button @click.prevent="$router.push({ name: 'DocumentForm' })" class="btn btn-primary float-right">
                <font-awesome-icon class="fa-lg" :icon="['fas', 'plus-circle']" /> Criar novo documento
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Info boxes -->
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6">
              <div class="info-box bg-info">
                <span class="info-box-icon">
                  <font-awesome-icon class="fa-lg" :icon="['fas', 'clock']" />
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">Total de tempo economizado (horas)</span>
                  <span class="info-box-number">
                    {{ items.filter((i, index) => index % 2 === 0).length * 5.50 +
                      items.filter((i, index) => index % 2 === 1).length * 9.45 }}
                  </span>
                </div>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-12 col-sm-6 col-md-6">
              <div class="info-box bg-success">
                <span class="info-box-icon">
                  <font-awesome-icon class="fa-lg" :icon="['fas', 'file-alt']" />
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">Total de documentos gerados</span>
                  <span class="info-box-number">{{ items.length }}</span>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-outline card-primary">
                    <div class="card-header">
                      <h3 class="card-title">Documentos recentes</h3>
                    </div>

                    <div class="card-body p-0 m-0">
                      <table class="table table-stripped table-bordered p-0 m-0">
                        <thead>
                          <tr>
                            <td width="1%">#</td>
                            <td width="25%">Tipo de documento</td>
                            <td width="25%">Orgão solicitante</td>
                            <td width="25%">Objeto</td>
                            <td width="40%">Ações</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr :key="item.id" v-for="(item, index) in items">
                            <td>{{ ++index }}</td>
                            <td>{{ item.template.name }}</td>
                            <td>{{ item.workflow.government_agency_requesting }}</td>
                            <td>{{ item.workflow.object_description }}</td>
                            <td>
                              <router-link :to="`/document-preview/${item.id}`" class="btn btn-secondary ml-1 mr-1">
                                <font-awesome-icon :icon="['fas', 'file']" /> {{ item['label'] }} Visualizar
                              </router-link>
                              <button class="btn btn-success" @click.prevent="download(item.id)">
                                <font-awesome-icon :icon="['fas', 'cloud-download']" /> {{ item['label'] }} Download
                                docx
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->

          <!-- Additional content could go here -->

        </div>
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import NavBar from './NavBar.vue';
import SideBar from './SideBar.vue';
import http from '@/utils/http'
export default {
  name: 'Dashboard',
  components: {
    NavBar,
    SideBar
  },
  data() {
    return {
      totalTimeSaved: 17, // Replace with actual data
      totalDocuments: 3, // Replace with actual data
      items: [],
    };
  },
  methods: {
    download(id) {
      const base_url = process.env.VUE_APP_API_URL
      window.open(base_url + `documents/${id}/download`)
    }
  },
  async mounted() {
    const templates = (await http.get('/documents/templates')).data.templates
    const documents = (await http.get('/documents')).data.documents
    for (const d of documents) {
      const doc_type = templates.find(t => t.id === d.document.document_type_id)
      this.items.push({
        'workflow': d.workflow,
        'template': doc_type,
        'id': d.document.id
      })
    }
  }
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>