<template>
  <div class="wrapper">
    <!-- Include the common sidebar and navbar components -->
    <nav-bar />
    <side-bar />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper" style="min-height: 100vh;">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <h1 class="m-0">Criar novo documento de licitação</h1>
        </div>
      </div>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="card card-primary card-outline">
            <div class="card-header">
              Criação de documento
            </div>

            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="row">
                  <div class="form-group col-md-4">
                    <label>A licitação é para material ou serviço?</label>
                    <select class="form-control" v-model="formData.tipoLicitacao" required>
                      <option value="" disabled selected>Selecione uma opção</option>
                      <option :key="t.id" v-for="t in documentTypes" :value="t.id">{{ t.name }}</option>

                    </select>
                  </div>
                  <div class="form-group col-md-8">
                    <label>Selecione o modelo</label>
                    <select class="form-control" v-model="formData.modelo" required>
                      <option value="" disabled selected>Selecione um modelo</option>
                      <option :key="t.external_id" v-for="t in templates" :value="t.external_id">{{ t.name }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <label>Qual o órgão demandante?</label>
                  <input type="text" class="form-control" v-model="formData.orgaoDemandante" required />
                </div>


                <div class="form-group">
                  <label>Qual é o objeto de compra da licitação?</label>
                  <input type="text" class="form-control" v-model="formData.objetoCompra" required />
                </div>
                <div class="form-group">
                  <label>Qual a finalidade dessa compra?</label>
                  <input type="text" class="form-control" v-model="formData.finalidadeCompra" required />
                </div>
                <button type="submit" class="btn btn-primary">
                  <font-awesome-icon class="fa-lg" :icon="['fas', 'wand-magic-sparkles']" /> Gerar documento com IA
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import SideBar from './SideBar.vue';
import http from '@/utils/http'
import Swal from 'sweetalert2'

export default {
  name: 'DocumentForm',
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      documentTypes: [{ id: 'material', name: 'MATERIAL' }, { id: 'servico', name: 'SERVIÇO' }],
      templates: [],
      formData: {
        tipoLicitacao: process.env.VUE_APP_ENVIRONMENT == 'development' ? 'material' : '',
        orgaoDemandante: process.env.VUE_APP_ENVIRONMENT == 'development' ? 'Secretaria de educação' : '',
        modelo: process.env.VUE_APP_ENVIRONMENT == 'development' ? '66e9b77b1e755b0086f46355' : '',
        objetoCompra: process.env.VUE_APP_ENVIRONMENT == 'development' ? 'Cadeira' : '',
        finalidadeCompra: process.env.VUE_APP_ENVIRONMENT == 'development' ? 'Inauguração laboratório de informática' : '',
      },
    };
  },
  async mounted() {
    if (this.$route.query.template_id) {
      this.formData.modelo = this.$route.query.template_id
    }
    this.templates = (await http.get('/documents/templates')).data.templates
  },
  methods: {
    async submitForm() {
      const response = await http.post('/workflows/', {
        'main_purpose': this.formData.finalidadeCompra,
        'government_agency_requesting': this.formData.orgaoDemandante,
        'object_description': this.formData.objetoCompra,
        'templates': [this.formData.modelo]
      })
      const document = response.data.documents[0]
      console.log('document', document)
      // const document = {
      //   document_id: 25, remaining_count_sections: 1, total_count_sections: 10
      // }
      Swal.fire({
        title: 'Processando...',
        html: 'Aguarde enquanto nossa IA organiza seu documento! Tempo aproximado: 5 minutos. <div class="m-4"><b></b></div>',
        icon: 'info',
        width: 800,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
          const dynamicText = Swal.getPopup().querySelector("b");
          let interval
          interval = setInterval(async () => {
            const responseCheckDocStatus = (await http.get(`/documents/${document.document_id}`)).data
            console.log('responseCheckDocStatus', responseCheckDocStatus)
            if (responseCheckDocStatus.document.total_count_sections > 0) {
              dynamicText.textContent = `concluído ${responseCheckDocStatus.document.total_count_sections - responseCheckDocStatus.document.remaining_count_sections} de ${responseCheckDocStatus.document.total_count_sections} etapas`
            }
            if (responseCheckDocStatus.document.processing_status === 'completed') {
              Swal.fire({
                title: 'Sucesso!',
                text: 'Seu documento foi gerado com sucesso!',
                icon: 'success'
              }).then(() => {
                clearInterval(interval)
                this.$router.push({ 'name': 'DocumentPreview', params: { id: document.document_id } });
              });
            }
          }, 5000);
        },

      });
    },
  },
};
</script>

<style scoped>
/* Add any styles if necessary */
</style>