<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <b>Login</b>
      </div>
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Insira suas informações</p>
          <form @submit.prevent="handleLogin">
            <div class="input-group mb-3">
              <input type="email" class="form-control" placeholder="Email" v-model="email" required />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input type="password" class="form-control" placeholder="Senha" v-model="password" required />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block rounded">
                  Entrar
                </button>
              </div>
            </div>
          </form>
          <!-- You can add additional links or messages here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'
export default {
  watch: {
  },
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await http.post('/token', new URLSearchParams({
          username: this.email,
          password: this.password,
        }), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        localStorage.setItem('token', response.data.access_token)
        this.$router.push('/dashboard')
      } catch (error) {
        const message = JSON.parse(error.request.response)
        alert(message.detail)
      }
    },
  },
};
</script>

<style scoped>
.login-page {
  /* background: url('@/assets/hero-banner.jpg') no-repeat center center fixed; */
  background-size: cover;
}
</style>