<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Add any global styles if needed */
</style>