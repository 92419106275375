import axios from 'axios';

// Criação da instância do Axios com configurações personalizadas
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json', // Cabeçalhos padrão
  },
  timeout: 10000, // Tempo limite para as requisições
});

// Interceptor de requisição
apiClient.interceptors.request.use(
  (config) => {
    // Adicione tokens de autenticação aqui, se necessário
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Erro ao enviar a requisição
    return Promise.reject(error);
  }
);

// Interceptor de resposta
apiClient.interceptors.response.use(
  (response) => {
    // Manipulação da resposta com sucesso
    return response;
  },
  (error) => {
    // Manipulação de erros
    if (error.response) {
      // Verifique o status HTTP da resposta
      if (error.response.status === 401) {
        // Exemplo: Redirecionar para a página de login se o token estiver inválido
        console.error('Não autorizado. Redirecionando para o login...');
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;